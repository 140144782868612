html,
body {
  margin: 0;
  background-color: #0D0D0D;
  font-family: Monserrat, sans-serif;
  color: white;
}

input{
  border-width: 1px;
  border-radius: 5px;
  font-size: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.center-elements {
  width: fit-content;
  margin: 2vh auto 2vh auto;
}

.title {
  margin: 2vh auto 2vh auto;
  width: 80%;
  text-align: center;
}

.code-input {
  font-size: 2rem;
  min-width: 0;
}

.settings-wrapper{
  margin: 10vh auto 0 auto;
}
.setting{
  width: 90%;
  background-color: #0583F2;
  border-radius: 5px;
  text-align: center;
}

.card{
  height: 15vh;
  width: 10vh;
  border-radius: 10px;
  background-color: bisque;
  cursor: pointer;
}

.card-image{
  width: 90%;
  height: 90%;
  margin: 5%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.player{
  background-color: #2785db;
}
.opponent{
  background-color: #d83939;
}

.board-container{
  width: 75%;
  margin: 5vh auto 0vh auto;
  display: grid;
  grid-template-columns: 5fr 1fr;
  column-gap: 1vw;
  padding: 1vh 2vw 1vh 2vw;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 10px;
  border: 5px solid rgba(255, 255, 255, 0.562);


  .gameboard {
    display: flex;
    flex-direction: column;
    .action-cards{
      display: flex;
      justify-content: space-evenly;
      width: 90%;
      margin: 2.5vh auto 2.5vh auto;
      border-radius: 10px;
      padding: 2.5vh 0 2.5vh 0;
    }
    .active-element-wrapper{
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 1vh;
      margin-bottom: 1vh;
    }
  }
  .player-info-wrapper{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    
    .player-info{
      display: flex;
      flex-direction: column;
      padding: 2.5vh 0 2.5vh 0;
      margin: 2.5vh auto 2.5vh auto;
      border-radius: 10px;
      width: 100%;
      font-size: 25px;
      input{
        width: 100%;
      }
      text-align: center;
    }
  }
}

.end-game-wrapper{
  display: flex;
  flex-direction: column;
  .winner-wrapper{
    display: grid;
    grid-template-columns:  2fr 1fr;
    column-gap: .25rem;
    min-width: 20%;
    margin: 3vh auto 2vh auto;
    .winner{
      border-radius: 3px;
      text-align: center;
      font-size: 2rem;
      padding: 0 1vw 0 1vw; 
    }
    .score{
      border-radius: 3px;
      text-align: center;
      font-size: 2rem;
      padding: 0 1vw 0 1vw; 
    }
  }
  .loser-wrapper{
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: .25rem;
    width: 15%;
    margin: 1vh auto 5vh auto;
    .loser{
      border-radius: 3px;
      font-size: 1.75rem;
      text-align: center;
      padding: 0 1vw 0 1vw; 
    }
    .score{
      border-radius: 3px;
      font-size: 1.75rem;
      text-align: center;
      padding: 0 1vw 0 1vw; 
    }
  }
  .button{
    margin: .5vh auto .5vh auto;
  }
  a{
    text-decoration: none;
  }
}

.credits{
  margin: 3vh auto 0 auto;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 3vh;
  a{
    color: white;
  }
}

.inactive{
  background-color: rgb(121, 121, 121);
}